/* eslint-disable lit-a11y/click-events-have-key-events */
import { html, css, LitElement } from 'lit';

export class BookNow extends LitElement {
  static get tag() {
    return `book-now`;
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        width: 100%;
        max-width: 400px;
      }
      :host * {
        box-sizing: border-box;
      }

      .bookNow {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 12px;
        width: 100%;
        height: 60px;
        background: var(--kiwi-main-color, #377a4e);
        border-radius: var(--kiwi-button-radius, 4px);
        text-decoration: none;
        color: var(--kiwi-text-color, #fff) !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.01em;
        box-sizing: border-box;
        text-align: center;
      }

      .button {
        display: flex;
        text-align: center;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 12px;
        height: 60px;
        background: var(--kiwi-main-color, #377a4e);
        border-radius: var(--kiwi-button-radius, 4px);
        text-decoration: none;
        color: var(--kiwi-text-color, #fff) !important;
        background-color: #ff;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.01em;
        width: 100%;
        box-sizing: border-box;
        border: none;
        cursor: pointer;
      }

      .small {
        height: 54px;
      }
    `;
  }

  static get properties() {
    return {
      checkoutUrl: { type: String },
      size: { type: String },
      onButtonClick: { type: Function },
      bookNowText: { type: String },
      queryParams: Object,
    };
  }

  constructor() {
    super();

    this.checkoutUrl = '';
    this.size = null;
    this.queryParams = null;
    this.bookNowText = 'Book Now';
    this.onButtonClick = () => {
      this.dispatchEvent(
        new CustomEvent('bookNowButtonClick', { bubbles: true })
      );
    };
  }

  connectedCallback() {
    super.connectedCallback();
  }

  getCheckoutUrl() {
    let url = this.checkoutUrl;

    if (this.queryParams) {
      const query = JSON.parse(this.queryParams);
      Object.keys(query).forEach(param => {
        url += `&${param}=${query[param]}`;
      });
    }

    return url;
  }

  render() {
    if (this.checkoutUrl) {
      return html` <a
        href="${this.getCheckoutUrl()}"
        target="_blank"
        class=${`bookNow ${this.size}`}
        id="bookNow"
      >
        ${this.bookNowText}
      </a>`;
    }
    return html`
      <div @click=${this.onButtonClick} class=${`button ${this.size}`}>
        ${this.bookNowText}
      </div>
    `;
  }
}

if (!window.customElements.get('book-now')) {
  window.customElements.define('book-now', BookNow);
}
