const hex2Rgb = color => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const calculateIlluminance = color => {
  const { r, g, b } = hex2Rgb(color);
  const a = [r, g, b].map(v => {
    // eslint-disable-next-line no-param-reassign
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

const getTextColor = color => {
  const illuminance = calculateIlluminance(color);
  return illuminance <= 0.5 ? '#fff' : '#000';
};

export { getTextColor, hex2Rgb };
