import { getTextColor, hex2Rgb } from './colorUtils.js';

const addLink = ({ href, id }) => {
  const existing = document.getElementById(id);
  if (existing) {
    return existing;
  }

  const link = document.createElement('link');

  link.rel = 'stylesheet';
  link.href = href;
  link.id = id;

  document.body.appendChild(link);

  return link;
};

const getBorderRadiuses = radiusStyle => {
  let borderRadius = '4px';
  let buttonRadius = '4px';

  if (radiusStyle === 'Round') {
    borderRadius = '8px';
    buttonRadius = '8px';
  }

  if (radiusStyle === 'Pill') {
    borderRadius = '8px';
    buttonRadius = '30px';
  }

  document.documentElement.style.setProperty(
    '--kiwi-button-radius',
    buttonRadius
  );
  document.documentElement.style.setProperty(
    '--kiwi-border-radius',
    borderRadius
  );
};

const applyBrandingsSettings = brandingSettings => {
  getBorderRadiuses(brandingSettings?.radiusStyle);
  const fontFamilyName =
    brandingSettings?.fontFamilyName || brandingSettings?.font?.name;

  if (fontFamilyName) {
    addLink({
      href: `https://fonts.googleapis.com/css2?family=${fontFamilyName.replaceAll(
        ' ',
        '+'
      )}:wght@400;600&display=swap`,
      id: 'font',
    });

    document.documentElement.style.setProperty(
      '--kiwi-font-family',
      `${fontFamilyName},-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`
    );
  }

  const mainColor = brandingSettings?.accentColor || '#ffc44e';
  const secondaryColor = brandingSettings?.brandColor || '#ffc44e';
  const textColor = getTextColor(brandingSettings?.accentColor || '#ffc44e');

  document.documentElement.style.setProperty('--kiwi-main-color', mainColor);
  document.documentElement.style.setProperty(
    '--kiwi-secondary-color',
    secondaryColor
  );
  document.documentElement.style.setProperty('--kiwi-text-color', textColor);

  const { r, g, b } = hex2Rgb?.(brandingSettings?.accentColor || '#ffc44e');
  document.documentElement.style.setProperty(
    '--kiwi-hover-color',
    `rgba(${r},${g},${b}, 0.1);`
  );
};

export default applyBrandingsSettings;
