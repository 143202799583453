import { html, css, LitElement } from 'lit';
import './components/BookNow.js';
import applyBrandingsSettings from './utils/brandingUtils.js';

let apiUrl = 'https://checkout-frontpage-api.test.kiwilaunch.com';

// eslint-disable-next-line no-undef
if (__environment__ === 'preprod') {
  apiUrl = 'https://checkout-frontpage-api.preprod.kiwilaunch.com';
}
// eslint-disable-next-line no-undef
else if (__environment__ === 'prod') {
  apiUrl = 'https://checkout-frontpage-api.kiwilaunch.com';
}
class KiwiLaunch extends LitElement {
  static get tag() {
    return `kiwi-launch`;
  }

  static get properties() {
    return {
      serviceId: { type: String },
      brandingSettings: Object,
      staffId: { type: String },
      funnelData: Object,
      queryParams: Object,
    };
  }

  constructor() {
    super();

    this.funnelData = null;
    this.queryParams = null;
    this.isLoaded = false;
    this.brandingSettings = null;
  }

  connectedCallback() {
    super.connectedCallback();
    this.fetchData();
  }

  fetchData() {
    fetch(
      `${apiUrl}/PreFunnels/${this.serviceId}${
        this.staffId ? `?staffId=${this.staffId}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic a2l3aTpub3RhZnJ1aXQ=',
        },
      }
    )
      .then(response => response.json())
      .then(funnelData => {
        this.funnelData = funnelData.data;
        if (this.brandingSettings) {
          applyBrandingsSettings({
            ...this.funnelData?.brandingSettings,
            ...JSON.parse(this.brandingSettings),
          });
        } else {
          applyBrandingsSettings(this.funnelData?.brandingSettings);
        }
        this.isLoaded = true;
      })
      .catch(() => {});
  }

  getContent() {
    if (this.funnelData?.name === 'appointmentAtHome') {
      const isZipcode = this.funnelData?.components?.find(
        x => x?.name === 'zipcode'
      );

      if (isZipcode) {
        import('./components/Zipcode.js');
        return html`<zip-code
          serviceId="${this.serviceId}"
          queryParams="${this.queryParams}"
          postcodeText=${this.funnelData?.locale?.Prefunnel?.EnterYourPostCode}
          bookNowText="${this.funnelData?.locale?.Prefunnel?.BookNow}"
          checkoutUrl="${this.funnelData.checkoutUrl}"
          zipcodeList="${this.funnelData?.components?.[1]?.fields?.[0]?.values}"
          apiUrl="${apiUrl}"
          country=${this.funnelData?.country}
        ></zip-code>`;
      }
      import('./components/TextArea.js');

      return html`<text-area
        serviceId="${this.serviceId}"
        queryParams="${this.queryParams}"
        funnelData="${JSON.stringify(this.funnelData)}"
        searchForAreaText=${this.funnelData?.locale?.Prefunnel?.SearchForArea}
      ></text-area>`;
    }

    if (this.funnelData?.name === 'fixedDate') {
      import('./components/FixedDate.js');

      return html`<fixed-date
        queryParams="${this.queryParams}"
        data="${JSON.stringify(this.funnelData)}"
      ></fixed-date>`;
    }

    if (this.funnelData?.name === 'appointmentWithPackages') {
      import('./components/PackageSelector.js');
      return html`<package-selector
        queryParams="${this.queryParams}"
        data="${JSON.stringify(this.funnelData)}"
      ></package-selector>`;
    }

    return html`<book-now
      bookNowText="${this.funnelData?.locale?.Prefunnel?.BookNow}"
      queryParams="${this.queryParams}"
      checkoutUrl="${this.funnelData?.checkoutUrl}"
    ></book-now>`;
  }

  static get styles() {
    return css`
      :host {
        font-family: var(--kiwi-font-family) !important;
      }
    `;
  }

  render() {
    if (!this.isLoaded) {
      return html``;
    }

    return html`
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <div class="kiwilaunch">${this.getContent()}</div>
    `;
  }
}

if (!window.customElements.get('kiwi-launch')) {
  window.customElements.define('kiwi-launch', KiwiLaunch);
}
